/* eslint-disable import/no-anonymous-default-export */

export default [
  {
    id: "Sewing",
    title: "Sewing",
    description:
      "In kids sewing classes, children learn basic sewing skills and techniques, such as threading a needle, tying knots, and stitching. They also learn about different types of fabrics, sewing machines, and tools used in sewing. As they progress, they may learn more advanced techniques, such as how to read and follow patterns, create buttonholes, and insert zippers. Through sewing projects, such as making simple clothing items, stuffed animals, or accessories, children develop their creativity, problem-solving skills, and attention to detail. Additionally, sewing classes may teach children about sustainability, as they learn how to upcycle old clothes or fabric scraps into new items.",
    location: "Guyana",
    img: require("./imgs/sewingCover.jpg"),
    lesson: "/projects/galleries/sewing",
    imgCard1: require("./imgs/Pictures/img1Sewing.jpg"),
    imgCard2: require("./imgs/Pictures/img2Sewing.jpg"),
    imgCard3: require("./imgs/Pictures/img3Sewing.jpg"),
  },
  {
    id: "Catering",
    title: "Catering",
    description:
      "Catering classes teach students how to plan, prepare, and serve food for various events and occasions. Students learn about food safety and hygiene, cooking techniques, menu planning, beverage service, presentation and decor, and catering business management. The goal of catering classes is to equip students with the skills and knowledge they need to succeed in the catering industry.",
    location: "Guyana",
    img: require("./imgs/cateringCover.jpeg"),
    lesson: "/projects/galleries/catering",
    imgCard1: require("./imgs/Pictures/img1Catering.jpg"),
    imgCard2: require("./imgs/Pictures/img2Catering.jpg"),
    imgCard3: require("./imgs/Pictures/img3Catering.jpg"),
  },
  {
    id: "IT",
    title: "IT",
    description:
      "IT classes cover a range of topics related to information technology, including computer hardware and software, programming languages, web development, database management, networking and cybersecurity, project management, and data analysis. Students will learn theory and practical skills through lectures, demonstrations, and hands-on practice with technology. They may work on individual or group projects, and may have the opportunity to earn industry certifications to demonstrate their skills to potential employers. Upon completion of the program, students may pursue careers in various IT fields, such as software development, network administration, cybersecurity, or data analysis.",
    location: "Guyana",
    img: require("./imgs/itCover.JPG"),
    lesson: "galleries/it",
    imgCard1: require("./imgs/Pictures/img1It.jpg"),
    imgCard2: require("./imgs/Pictures/img2It.jpg"),
    imgCard3: require("./imgs/Pictures/img3It.jpg"),
  },
  {
    id: "ElectricalRepairs",
    title: "Electrical Repairs",
    description:
      "Electrical repair classes typically cover topics such as electrical theory, wiring, circuitry, safety procedures, and troubleshooting techniques. Students learn how to read and interpret electrical diagrams, use electrical testing equipment, and repair electrical components such as motors, transformers, and circuit breakers. The goal of the classes is to provide students with the knowledge and skills necessary to diagnose and repair common electrical problems in residential, commercial, and industrial settings.",
    location: "Guyana",
    img: require("./imgs/electricalCover.jpg"),
    lesson: "galleries/electrical",
    imgCard1: require("./imgs/Pictures/img1Electrical.jpg"),
    imgCard2: require("./imgs/Pictures/img2Electrical.jpg"),
    imgCard3: require("./imgs/Pictures/img3Electrical.jpg"),
  },
  {
    id: "Cosmetology",
    title: "Cosmetology",
    description:
      "Cosmetology classes cover a range of topics related to beauty, including anatomy and physiology, skin care, hair styling and cutting, chemical treatments, nail care, makeup application, sanitation and safety, business and salon management, and customer service. Students will learn theory, as well as practical skills and techniques through hands-on practice, and may have the opportunity to work with clients in a supervised setting. Upon completion of the program, students may be eligible to take a state licensing exam to become a licensed cosmetologist.",
    location: "Guyana",
    img: require("./imgs/cosmetologyCover.jpg"),
    lesson: "galleries/cosmetology",
    imgCard1: require("./imgs/Pictures/img1Cosmetology.jpg"),
    imgCard2: require("./imgs/Pictures/img2Cosmetology.jpg"),
    imgCard3: require("./imgs/Pictures/img3Cosmetology.jpg"),
  },
  {
    id: "fabric-design",
    title: "Fabric Design",
    description: `In fabric design classes, students learn how to create original designs for textiles, such as patterns, colors, and textures. They are introduced to different techniques such as drawing, painting, digital software, and screen printing. They also learn about the properties of various fabrics and how to manipulate them to achieve the desired effect. Students may work on projects such as designing and printing their own fabrics, creating a collection for a specific market, or collaborating with other students to create a large-scale installation. Throughout the classes, students receive feedback and critique from their instructor and peers, allowing them to refine their skills and develop their own style.`,
    location: "Guyana",
    img: require("./imgs/fabricCover.jpg"),
    lesson: "galleries/fabric-design",
    imgCard1: require("./imgs/Pictures/img1Fabric.jpg"),
    imgCard2: require("./imgs/Pictures/img2Fabric.jpg"),
    imgCard3: require("./imgs/Pictures/img3Fabric.jpg"),
  },
  {
    id: "Crafting",
    title: "Crafting",
    description: `Crafting classes teach students various hands-on skills and techniques, such as knitting, crocheting, embroidery, quilting, beading, or woodworking, to create unique items. Students work on specific projects during the class and apply the learned techniques. The class may also involve learning about different materials, tools, and methods to improve their crafting abilities. Crafting classes also provide opportunities for students to explore their creativity, express themselves artistically, and develop their problem-solving skills. In addition to learning specific techniques, students may also learn about different materials, tools, and methods to improve their crafting abilities.`,
    location: "Guyana",
    img: require("./imgs/craftingCover.jpg"),
    lesson: "galleries/crafting",
    imgCard1: require("./imgs/Pictures/img1Crafting.jpg"),
    imgCard2: require("./imgs/Pictures/img2Crafting.jpg"),
    imgCard3: require("./imgs/Pictures/img3Crafting.jpg"),
  },
  {
    id: "event-planning",
    title: "Event Planning",
    description: `Event planning classes teach students how to plan, coordinate, and execute successful events. The classes cover topics such as event design, budgeting, vendor selection, event logistics, and marketing and promotion. Students work on real-world projects and gain hands-on experience in event planning. The classes also cover legal and ethical considerations of event planning. The goal is to prepare students for a career in event planning or to help them plan successful events for their personal or professional lives.`,
    location: "Guyana",
    img: require("./imgs/eventCover.jpg"),
    lesson: "galleries/event-planning",
    imgCard1: require("./imgs/Pictures/img1Event.jpg"),
    imgCard2: require("./imgs/Pictures/img2Event.jpg"),
    imgCard3: require("./imgs/Pictures/img3Event.jpg"),
  },
];
